// @ts-nocheck
import Local from './local-storage';
import { isObject } from './type';
import { localStorageSetItem, localStorageSetItems } from './native/storage';
import { humpToLine } from './tools';

/**
 * 缓存用户上次筛选条件
 */
class UserCitySelect {
    constructor(key: string = 'CRD_ZZC_USER_CITY_SELECT') {
        this.key = key;
    }

    /**
     * 获取用户上次筛选的缓存
     */
    get() {
        return new Promise(resolve => {
            const data = Local.get(this.key) || {};
            resolve(data);
        });
    }

    /** 同步获取 */
    syncGet(){
        return Local.get(this.key) || {};
    }

    /**
     * 保存用户上次查询条件
     * @param {object} data 保存数据对象
     */
    save(data) {
        this.get().then(res => {
            const obj = {
                ...res,
                ...data,
            };
            Local.set(this.key, obj, 60);

            localStorageSetItems('ZZCCrcAppLocalStorage', obj);
            // 格式化数据，使跟user-last-select存储的数据格式是一样的
            // Object.keys(obj).forEach(objKey => {
            //     const innerObj = obj[objKey];
            //     obj.oPlace = !!innerObj.oplace;

            //     delete innerObj.pickTime;
            //     delete innerObj.dropTime;
            //     delete innerObj.channel;
            //     delete innerObj.oplace;
            // });
            handleSaveToApp(obj);
        });
    }

    remove() {
        Local.remove(this.key);
    }
}

// http://wiki.int.zuzuche.info/pages/viewpage.action?pageId=169541800
const nullableKeys = [ 'm_zzc_crc_get_landmark_type', 'm_zzc_crc_return_landmark_type' ];
const isAllowNull = (key)=> nullableKeys.some( str => str === key);
/**
 * 存储至app
 * @param {object} obj 存储到app的数据
 */
function handleSaveToApp(obj) {
    Object.keys(obj).map(key => {
        const PREFIX =
            key === 'pick'
                ? `m_zzc_crc_get_`
                : key === 'drop'
                ? `m_zzc_crc_return_`
                : `m_zzc_crc_`;
        const item = obj[key];
        console.log(item)
        if (isObject(item)) {
            for (let k in item) {
                //  部分key需要设置为空值
                const fullKey = `${PREFIX}${humpToLine(k)}`;
                if(item[k] || isAllowNull(fullKey)){
                    localStorageSetItem(
                        'ZZCCrcAppLocalStorage',
                        fullKey,
                        String(item[k])
                    );
                };
            }
        } else {
            if(item){
                localStorageSetItem(
                    'ZZCCrcAppLocalStorage',
                    `${PREFIX}${humpToLine(key)}`,
                    String(item)
                );
            };

        }
    });
}

const CitySelect = new UserCitySelect();
// 国际包车使用
export const CitySelectChartered = new UserCitySelect('CRD_ZZC_USER_CITY_SELECT_INTL_CHARTER');

export default CitySelect;
